@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-align: center;
}

.background {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-position-x: 90%;
}

.background::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    pointer-events: none;
    animation: fade-out 3s forwards;
}

/* Home */
.home-background {
    background-image: url(/static/media/menu-background.b80309a7.png);
    animation: move-background 60s linear infinite alternate;
}

.home-logo {
    width: 90%;
    padding-bottom: 50px;
    -webkit-filter: drop-shadow(0px 0px 5px black);
            filter: drop-shadow(0px 0px 5px black);
}

.home-button {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 2.5rem;
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 20px;
    margin: 10px 0;
    border-radius: 10px;
}

/* Game */
.day-background {
    background-image: url(/static/media/day-background.025a8f6c.jpg);
}

.night-background {
    background-image: url(/static/media/night-background.2682446e.jpg);
}

.game-text {
    font-size: 4.5rem;
    text-transform: uppercase;
    font-weight: bold;
    -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.6));
            filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.6));
}

.game-counter {
    margin-top: 0.5em;
    font-size: 4rem;
    margin-bottom: 1em;
    -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.6));
            filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.6));
}

.game-end-button {
    text-decoration: none;
    position: absolute;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    bottom: 48px;
    border-radius: 8px;
    padding: 1em 0;
    width: 90%;
    font-size: 1.25rem;
    background: none;
    color: white;
    border: 2px solid white;
}

/* End game */
.endgame-background {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/menu-background.b80309a7.png) no-repeat fixed;
}

.endgame-buttons {
    margin-top: 3em;
    width: 100%;
}

.endgame-button {
    width: 90%;
    padding: 0.75em 0;
    font-size: 1.5rem;
    border: none;
    color: white;
    border-radius: 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}

.assassin-button {
    background-color: rgba(138, 0, 0, 0.8);
    margin-bottom: 0.5em;
}

.innocent-button {
    background-color: rgba(0, 100, 0, 0.8);
}

/* Instructions */
.instructions-background {
    background-position-x: 60%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/menu-background.b80309a7.png) no-repeat fixed;
    justify-content: flex-start;
    padding: 2em 0 7.5em 0;
    height: unset;
}

.instructions-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.instructions-title {
    margin: 2em 0 0.75em;
    font-size: 2.5rem;
}

.instructions-text {
    width: 90%;
    max-width: 400px;
}

.instructions-text > h3 {
    font-weight: bold;
    font-size: 1.75rem;
    margin: 1.5em 0 0.5em;
    text-align: left;
}

.instructions-text > p {
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.5em;
    margin-bottom: 1.5em;
    text-align: left;
}

.instructions-text > ul > li {
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.5em;
    width: 80%;
    margin: 0.5em auto;
    text-align: left;
}

/* Settings */
.settings-background {
    background-position-x: 60%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/menu-background.b80309a7.png) no-repeat fixed;
    justify-content: flex-start;
    padding-top: 156px;
}

.settings-header {
    font-size: 2.5rem;
    margin-bottom: 0.5em;
    text-transform: capitalize;
}

.dropdown-container {
    font-size: 1.5rem;
    font-weight: normal;
    width: 70%;
    color: black;
    text-align: left;
}

.dropdown__control {
    padding: 0.5em 0.25em;
}

/* Safari */
.safari-header,
.safari-text {
    color: black;
    padding: 0.5em 10%;
}

.safari-text {
    font-weight: normal;
    margin-bottom: 2em;
}

.safari-logo {
    width: 10rem;
    margin-bottom: 2em;
}

.apple-download {
    width: 16rem;
}

/* Animations */
@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes move-background {
    from {
        background-position-x: 80%;
    }
    to {
        background-position-x: 20%;
    }
}

.fade-enter::after,
.fade-appear::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 1;
    pointer-events: none;

    transition: opacity 3s;
}

.fade-enter-active::after,
.fade-appear-active::after {
    opacity: 0;
}

.fade-exit::after {
    opacity: 0;
    transition: opacity 1500ms;
}

.fade-exit-active::after {
    opacity: 1;
}

/* Navbar */
.icon-button {
    -webkit-filter: drop-shadow(0px 0px 1px black);
            filter: drop-shadow(0px 0px 1px black);
    position: absolute;
    top: 0;
    color: white;
    font-size: 2.25rem;
    margin: 0.75em;
    animation: fade-in 3.5s;
}

.back-button {
    left: 0;
}

.play-button,
.settings-button {
    right: 0;
}

/* Privacy Policy */
.privacy-container {
    max-width: 60ch;
    text-align: left;
    color: black;
    margin: 4rem auto;
    padding: 0 1em;
}

.privacy-container > strong {
    font-size: 2rem;
}

.privacy-container > div > p,
.privacy-container > p {
    margin: 1em 0;
    font-weight: 400;
}

.privacy-container > div > ul > li,
.privacy-container > ul > li {
    font-weight: 400;
}

.privacy-container > div > ul,
.privacy-container > ul {
    margin: 1em 0;
    padding-left: 1em;
}

